import React from "react";
 const Login = () =>{

    return(
        <>

            <div class="page-banner-area">
        <div class="container">
            <div class="page-banner-content">
                <h2>Login / Register</h2>
                <ul class="list">
                    <li>
                        <a href="index/">Home</a>
                    </li>
                    <li>Account</li>
                </ul>
            </div>
        </div>
    </div>
    
    <div class="profile-authentication-area ptb-120">
        <div class="container">
            <div class="profile-authentication-inner">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="profile-authentication-box">
                            <div class="content">
                                <h3>Sign In</h3>
                                <p>Don't have an account yet? <a href="#/Signup">Sign up here</a></p>
                            </div>
                            <form class="authentication-form">
                                <div class="google-btn">
                                    {/* <button type="submit">
                                        <img src="../../templates.envytheme.com/andora/default/assets/images/google.svg" alt="google">
                                            Sign in with Google</button> */}
                                </div>
                                <div class="or">
                                    <span>OR</span>
                                </div>
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="email" class="form-control" placeholder="Enter email address"/>
                                    <div class="icon">
                                        <i class="ri-mail-line"></i>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Your Password</label>
                                    <input type="password" class="form-control" placeholder="Your password"/>
                                    <div class="icon">
                                        <i class="ri-lock-line"></i>
                                    </div>
                                </div>
                                <div class="form-bottom d-flex justify-content-between">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="remember-me"/>
                                        <label class="form-check-label" for="remember-me">
                                            Remember me
                                        </label>
                                    </div>
                                    <a href="#/" class="forgot-password">
                                        Forgot your password?
                                    </a>
                                </div>
                                <button type="submit" class="default-btn">Sign In</button>
                            </form>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>

    <div class="subscribe-wrap-area">
        <div class="container" data-cues="slideInUp">
            <div class="subscribe-wrap-inner-area">
                <div class="subscribe-content">
                    <h2>Subscribe To Our Newsletter</h2>
                    <form class="subscribe-form">
                        <input type="search" class="form-control" placeholder="Enter your email"/>
                        <button type="submit" class="default-btn">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
 
    <button type="button" id="back-to-top" class="position-fixed text-center border-0 p-0">
        <i class="ri-arrow-up-s-line"></i>
    </button>
        </>

)



}

export default Login;