import React from "react";
 const Signup = () =>{

    return(
        <>

        <div class="page-banner-area">
    <div class="container">
        <div class="page-banner-content">
            <h2>Register</h2>
            <ul class="list">
                <li>
                    <a href="index/">Home</a>
                </li>
                <li>Account</li>
            </ul>
        </div>
    </div>
</div>

<div class="profile-authentication-area ptb-120">
    <div class="container">
        <div class="profile-authentication-inner">
            <div class="row justify-content-center">
              
                <div class="col-lg-6 col-md-12">
                    <div class="profile-authentication-box with-gap">
                        <div class="content">
                            <h3>Create Your Account</h3>
                            <p>Already have an account? <a href="#/">Sign in here</a></p>
                        </div>
                        <form class="authentication-form">
                            {/* <div class="google-btn">
                                <button type="submit">
                                    <img src="../../templates.envytheme.com/andora/default/assets/images/google.svg" alt="google">
                                        Sign in with Google</button>
                            </div> */}
                            <div class="or">
                                <span>OR</span>
                            </div>
                            <div class="form-group">
                                <label>Your Name</label>
                                <input type="text" class="form-control" placeholder="Enter name"/>
                                <div class="icon">
                                    <i class="ri-user-3-line"></i>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Email Address</label>
                                <input type="email" class="form-control" placeholder="Enter email address"/>
                                <div class="icon">
                                    <i class="ri-mail-line"></i>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Your Password</label>
                                <input type="password" class="form-control" placeholder="Your password"/>
                                <div class="icon">
                                    <i class="ri-lock-line"></i>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Confirm Password</label>
                                <input type="password" class="form-control" placeholder="Confirm password"/>
                                <div class="icon">
                                    <i class="ri-lock-line"></i>
                                </div>
                            </div>
                            <div class="form-bottom">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkbox1"/>
                                    <label class="form-check-label" for="checkbox1">
                                        I accept the <a href="terms-conditions/">Terms and Conditions</a>
                                    </label>
                                </div>
                            </div>
                            <button type="submit" class="default-btn">Sign Up</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="subscribe-wrap-area">
    <div class="container" data-cues="slideInUp">
        <div class="subscribe-wrap-inner-area">
            <div class="subscribe-content">
                <h2>Subscribe To Our Newsletter</h2>
                <form class="subscribe-form">
                    <input type="search" class="form-control" placeholder="Enter your email"/>
                    <button type="submit" class="default-btn">Subscribe</button>
                </form>
            </div>
        </div>
    </div>
</div>

<button type="button" id="back-to-top" class="position-fixed text-center border-0 p-0">
    <i class="ri-arrow-up-s-line"></i>
</button>
    </>

        
)



}

export default Signup;