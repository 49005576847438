import React from "react";
 const Postproperties = () =>{

    return(
        <>








  <div class="page-banner-area">
            <div class="container">
                <div class="page-banner-content">
                    <h2>Add Property</h2>
                    <ul class="list">
                        <li>
                            <a href="index/">Home</a>
                        </li>
                        <li>Add Property</li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class="add-listing-area ptb-120">
            <div class="container">
                <div class="add-property-form">
                    <h3>Add Property</h3>
                    <form>
                        <div class="row justify-content-center">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Title (mandatory)</label>
                                    <input type="text" class="form-control" placeholder="Example title"/>
                                </div>
                            </div>
                            <div class="col-lg-12-col-md-12">
                                <div class="form-group extra-top">
                                    <label>Description</label>
                                    <textarea class="form-control" placeholder="Your message here"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>Area</label>
                                    <input type="number" class="form-control" placeholder="Minimum Size (Sq Ft)"/>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>Price (USD)</label>
                                    <input type="number" class="form-control" placeholder="Max Price"/>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>Status</label>
                                    <select class="form-select form-control">
                                        <option selected="">Status</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>Property Type</label>
                                    <select class="form-select form-control">
                                        <option selected="">Select Type</option>
                                        <option value="1">Villa</option>
                                        <option value="2">Single Family Home</option>
                                        <option value="3">Multi Family Home</option>
                                        <option value="4">Apartment</option>
                                        <option value="5">Office</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>Bedrooms</label>
                                    <input type="number" class="form-control" placeholder="Number Of Beds"/>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>Bathrooms</label>
                                    <input type="number" class="form-control" placeholder="Number Of Baths"/>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Featured Image</label>
                                    <input type="file" class="form-control file-content"/>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Upload Gallery</label>
                                    <input type="file" class="form-control file-content"/>
                                </div>
                            </div>
                        </div>
                        <div class="facilities">
                            <h3>Facilities</h3>
                            <ul class="check-list">
                                <li>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault1"/>
                                        <label class="form-check-label" for="flexCheckDefault1">
                                            Free Wifi
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault2"/>
                                        <label class="form-check-label" for="flexCheckDefault2">
                                            Pets Friendly
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault3"/>
                                        <label class="form-check-label" for="flexCheckDefault3">
                                            Smoking Allowed
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault4"/>
                                        <label class="form-check-label" for="flexCheckDefault4">
                                            Elevator In Building
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault5"/>
                                        <label class="form-check-label" for="flexCheckDefault5">
                                            Instant Book
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault6"/>
                                        <label class="form-check-label" for="flexCheckDefault6">
                                            Wireless Internet
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault7"/>
                                        <label class="form-check-label" for="flexCheckDefault7">
                                            Free Parking
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="location-info">
                            <h3>Location Information</h3>
                            <div class="row justify-content-center">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Select Country</label>
                                        <select class="form-select form-control">
                                            <option selected="">Select Country</option>
                                            <option value="1">Germany</option>
                                            <option value="2">United Kingdom</option>
                                            <option value="3">Switzerland</option>
                                            <option value="4">Canada</option>
                                            <option value="5">Australia</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label>Address</label>
                                        <input type="text" class="form-control" placeholder="Address"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label>City</label>
                                        <select class="form-select form-control">
                                            <option selected="">Select City</option>
                                            <option value="1">Tokyo</option>
                                            <option value="2">Beijing</option>
                                            <option value="3">Mexico City</option>
                                            <option value="4">Osaka</option>
                                            <option value="5">Shanghai</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label>State</label>
                                        <select class="form-select form-control">
                                            <option selected="">Select State</option>
                                            <option value="1">Alberta</option>
                                            <option value="2">British Columbia</option>
                                            <option value="3">New Brunswick</option>
                                            <option value="4">Newfoundland and Labrador</option>
                                            <option value="5">Nova Scotia</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label>Zip Code</label>
                                        <input type="text" class="form-control" placeholder="Zip Code"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bottom">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="checkbox1"/>
                                <label class="form-check-label" for="checkbox1">
                                    Hide pricing table on listing page but show bookable services
                                </label>
                            </div>
                            <button class="default-btn">Submit Property</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>






        </>


    )



}

export default Postproperties;