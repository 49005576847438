import React from "react";
import bed from '../../images/bed.svg'
import bed2 from '../../images/bed2.svg'

import bathroom from '../../images/bathroom.svg'
import bathroom2 from '../../images/bathroom2.svg'
import home from '../../images/home.svg'
import flor from '../../images/floor-plan.jpg'
import video from '../../images/video.jpg'





import parking from '../../images/parking.svg'
import parking2 from '../../images/parking2.svg'

import area from '../../images/area.svg'
import area2 from '../../images/area2.svg'
import user2 from '../../images/user1.png'

import Logo from "../../images/Logo.webp"
import bed_1 from '../../images/bed_1.svg'
import bathroom_1 from '../../images/bathroom_1.svg'
import parking_1 from '../../images/parking_1.svg'
import area_1 from '../../images/arrow_1.svg'
import agents1 from '../../images/agents1.jpg'
import agents2 from '../../images/agents2.jpg'
import agents3 from '../../images/agents3.jpg'
import agents4 from '../../images/agents4.jpg'
import blog1 from '../../images/blog1.jpg'
import blog2 from '../../images/blog2.jpg'
import blog3 from '../../images/blog3.jpg'
import blog4 from '../../images/blog4.jpg'
import pro1 from '../../images/properties1.jpg'
import pro2 from '../../images/properties2.jpg'
import pro3 from '../../images/properties3.jpg'
import pro4 from '../../images/properties4.jpg'
import prolarge from '../../images/property-details-large.jpg'






const Details = () =>{

return(
    
    <div>

     

 
        <div class="page-banner-area">
            <div class="container">
                <div class="page-banner-content">
                    <h2>Property Details</h2>
                    <ul class="list">
                        <li>
                            <a href="index.html">Home</a>
                        </li>
                        <li>Single Property</li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class="property-details-area ptb-120">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="property-details-desc">
                        <div class="property-details-content">
                            <div class="row justify-content-center align-items-center">
                                <div class="col-lg-7 col-md-12">
                                    <div class="left-content">
                                        <div class="title">
                                            <h2>Luxury Apartments</h2>
                                            <a href="property-grid.html" class="featured-btn">
                                                Featured
                                            </a>
                                        </div>
                                        <span class="address">194 Mercer Street, NY 10012, USA</span>
                                        <ul class="info-list">
                                            <li>
                                                <div class="icon">
                                                    <img src={bed2} alt="" />
                                                </div>
                                                <span>6 Bedroom</span>
                                            </li>
                                            <li>
                                                <div class="icon">
                                                    <img src={bathroom2} alt="" />

                                                </div>
                                                <span>4 Bathroom</span>
                                            </li>
                                            <li>
                                                <div class="icon">
                                                    <img src={parking2} alt="" />

                                                </div>
                                                <span>1 Parking</span>
                                            </li>
                                            <li>
                                                <div class="icon">
                                                    <img src={area2} alt="" />

                                                </div>
                                                <span>3250 Area</span>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-12">
                                    <div class="right-content">
                                        <ul class="link-list">
                                            <li>
                                                <a href="property-grid.html" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="property-grid.html" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <div class="price">$95,000</div>
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="agent-profile.html">Thomas Klarck</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="property-details-image">
                            <div class="row justify-content-center align-items-center">
                                <div class="col-lg-4 col-md-12">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-6 col-sm-6">
                                            <div class="block-image">
                                                <img src={pro1} alt="" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-sm-6">
                                            <div class="block-image">
                                                <img src={pro2} alt="" />

                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-sm-6">
                                            <div class="block-image">
                                                <img src={pro3} alt="" />

                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-sm-6">
                                            <div class="block-image">
                                            <img src={pro4} alt="" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <div class="block-image">
                                        <img src={prolarge} alt="" />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="property-details-inner-content">
                            <div class="row justify-content-center">
                                <div class="col-xl-8 col-md-12">
                                    <div class="description">
                                        <h3>Property Description</h3>
                                        <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. Class aptent taciti sociosqu.</p>
                                        <p>Gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi acnec tellus a odio tincidunt auctor a ornare odio.</p>
                                    </div>
                                    <div class="overview">
                                        <h3>Property Overview</h3>
                                        <ul class="overview-list">
                                            <li>
                                                <img src={bed2} width={40} alt="" />
                                                <h4>Bedrooms</h4>
                                                <span>4 Bedrooms / 1 Guestroom</span>
                                            </li>
                                            <li>
                                                <img src={bathroom2} width={40} alt="" />
                                                <h4>Bedrooms</h4>
                                                <span>4 Bedrooms / 1 Guestroom</span>
                                            </li>
                                            <li>
                                                <img src={parking2} width={40} alt="" />

                                                <h4>Parking</h4>
                                                <span>Free Parking for 4 Cars</span>
                                            </li>
                                            <li>
                                                <img src={area2} width={40} alt="" />

                                                <h4>Accommodation</h4>
                                                <span>6 Guest / 2980 Sq Ft</span>
                                            </li>
                                            <li>
                                                <img src={home} width={40} alt="" />

                                                <h4>Property Type</h4>
                                                <span>Entire Place / Apartment</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="features">
                                        <h3>Facts And Features</h3>
                                        <div class="row justify-content-center">
                                            <div class="col-lg-4 col-md-4">
                                                <ul class="list">
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Air Conditioning
                                                    </li>
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Dishwasher
                                                    </li>
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Internet
                                                    </li>
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Supermarket/Store
                                                    </li>
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Build-In Wardrobes
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-lg-4 col-md-4">
                                                <ul class="list">
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Fencing
                                                    </li>
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Park
                                                    </li>
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Swimming Pool
                                                    </li>
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Clinic
                                                    </li>
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Floor Coverings
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-lg-4 col-md-4">
                                                <ul class="list">
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        School
                                                    </li>
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Transportation Hub
                                                    </li>
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Gym Availability
                                                    </li>
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Lawn
                                                    </li>
                                                    <li>
                                                        <i class="ri-check-double-fill"></i>
                                                        Security Guard
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="floor-plan">
                                        <div class="title">
                                            <h3>Floor Plan</h3>
                                            <ul class="info-list">
                                                <li>
                                                    <div class="icon">
                                                        <img src={bed2} alt="" />
                                                    </div>
                                                    <span>6</span>
                                                </li>
                                                <li>
                                                    <div class="icon">
                                                        <img src={bathroom2} alt="" />

                                                    </div>
                                                    <span>4</span>
                                                </li>
                                                <li>
                                                    <div class="icon">
                                                        <img src={parking2} alt="" />

                                                    </div>
                                                    <span>1</span>
                                                </li>
                                                <li>
                                                    <div class="icon">
                                                        <img src={area2} alt="" />

                                                    </div>
                                                    <span>3250</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="floor-image">
                                            <img src={flor} alt="" />
                                        </div>
                                    </div>
                                    <div class="video">
                                        <h3>Property video</h3>
                                        <div class="inner">
                                            <img src={video} alt="" />
                                            <a data-fslightbox="video" class="video-btn" href="https://youtu.be/KUbFY0RxsGg">
                                                <i class="ri-play-fill"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="location">
                                        <div class="title">
                                            <h3>Location</h3>
                                            <p>194 Mercer Street, NY 10012, USA</p>
                                        </div>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.6247953285215!2d-73.99921922358588!3d40.726275536740864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2598fc76faf1d%3A0x8e82ec28918ad76e!2s194%20Mercer%20Street%2C%20New%20York%2C%20NY%2010012%2C%20USA!5e0!3m2!1sen!2sbd!4v1703668602409!5m2!1sen!2sbd"></iframe>
                                    </div>
                                    <div class="conclusion">
                                        <h3>Conclusion</h3>
                                        <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. Class aptent taciti sociosqu.</p>
                                        <p>Gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi acnec tellus a odio tincidunt auctor a ornare odio.</p>
                                    </div>
                               
                                </div>
                                <div class="col-xl-4 col-md-12">
                                    <div class="property-details-sidebar">
                                        <div class="booking">
                                            <form>
                                                <div class="form-group">
                                                    <label>Name</label>
                                                    <input type="text" placeholder="Your name" class="form-control"/>
                                                    <div class="icon">
                                                        <i class="ri-user-3-line"></i>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Email</label>
                                                    <input type="text" placeholder="Your email" class="form-control"/>
                                                    <div class="icon">
                                                        <i class="ri-mail-send-line"></i>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Phone No.</label>
                                                    <input type="text" placeholder="+12345678" class="form-control"/>
                                                    <div class="icon">
                                                        <i class="ri-phone-line"></i>
                                                    </div>
                                                </div>
                                                <div class="form-group extra-top">
                                                    <label>Description</label>
                                                    <textarea class="form-control" placeholder="I'm interested in this property......." rows="5"></textarea>
                                                    <div class="icon">
                                                        <i class="ri-pencil-line"></i>
                                                    </div>
                                                </div>
                                                <button type="submit" class="default-btn">Submit Request</button>
                                            </form>
                                        </div>
                                        <div class="featured-properties">
                                            <h3>Featured Properties</h3>
                                            <div class="swiper featured-properties-slider">
                                                <div class="swiper-wrapper">
                                                    {/* <div class="swiper-slide">
                                                        <div class="properties-item">
                                                            <div class="properties-image">
                                                                <a href="property-details.html">
                                                                    <img src="images/properties1.jpg" alt="image">
                                                                </a>
                                                                <ul class="action">
                                                                    <li>
                                                                        <a href="property-grid.html" class="featured-btn">Featured</a>
                                                                    </li>
                                                                    <li>
                                                                        <div class="media">
                                                                            <span><i class="ri-vidicon-fill"></i></span>
                                                                            <span><i class="ri-image-line"></i>5</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul class="link-list">
                                                                    <li>
                                                                        <a href="property-grid.html" class="link-btn">Apartment</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="property-grid.html" class="link-btn">For Sale</a>
                                                                    </li>
                                                                </ul>
                                                                <ul class="info-list">
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/bed_1.svg" alt="bed">
                                                                        </div>
                                                                        <span>6</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/bathroom_1.svg" alt="bathroom">
                                                                        </div>
                                                                        <span>4</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/parking_1.svg" alt="parking">
                                                                        </div>
                                                                        <span>1</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/area_1.svg" alt="area">
                                                                        </div>
                                                                        <span>3250</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="properties-content">
                                                                <div class="top">
                                                                    <div class="title">
                                                                        <h3>
                                                                            <a href="property-details.html">Vacation Homes</a>
                                                                        </h3>
                                                                        <span>194 Mercer Street, NY 10012, USA</span>
                                                                    </div>
                                                                    <div class="price">$95,000</div>
                                                                </div>
                                                                <div class="bottom">
                                                                    <div class="user">
                                                                        <img src="images/user1.png" alt="image">
                                                                        <a href="agent-profile.html">Thomas Klarck</a>
                                                                    </div>
                                                                    <ul class="group-info">
                                                                        <li>
                                                                            <div class="dropdown">
                                                                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <i class="ri-share-line"></i>
                                                                                </button>
                                                                                <ul class="dropdown-menu">
                                                                                    <li>
                                                                                        <a href="https://www.facebook.com/" target="_blank">
                                                                                            <i class="ri-facebook-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://twitter.com/" target="_blank">
                                                                                            <i class="ri-twitter-x-line"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://www.instagram.com/" target="_blank">
                                                                                            <i class="ri-instagram-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://bd.linkedin.com/" target="_blank">
                                                                                            <i class="ri-linkedin-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                                                <i class="ri-heart-line"></i>
                                                                            </button>
                                                                        </li>
                                                                        <li>
                                                                            <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                                                <i class="ri-arrow-left-right-line"></i>
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="swiper-slide">
                                                        <div class="properties-item">
                                                            <div class="properties-image">
                                                                <a href="property-details.html">
                                                                    <img src="images/properties2.jpg" alt="image">
                                                                </a>
                                                                <ul class="action">
                                                                    <li>
                                                                        <div class="media">
                                                                            <span><i class="ri-vidicon-fill"></i></span>
                                                                            <span><i class="ri-image-line"></i>5</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul class="link-list">
                                                                    <li>
                                                                        <a href="property-grid.html" class="link-btn">Apartment</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="property-grid.html" class="link-btn">For Sale</a>
                                                                    </li>
                                                                </ul>
                                                                <ul class="info-list">
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/bed_1.svg" alt="bed">
                                                                        </div>
                                                                        <span>6</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/bathroom_1.svg" alt="bathroom">
                                                                        </div>
                                                                        <span>4</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/parking_1.svg" alt="parking">
                                                                        </div>
                                                                        <span>1</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/area_1.svg" alt="area">
                                                                        </div>
                                                                        <span>3250</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="properties-content">
                                                                <div class="top">
                                                                    <div class="title">
                                                                        <h3>
                                                                            <a href="property-details.html">Industrial Spaces</a>
                                                                        </h3>
                                                                        <span>194 Mercer Street, NY 10012, USA</span>
                                                                    </div>
                                                                    <div class="price">$55,000</div>
                                                                </div>
                                                                <div class="bottom">
                                                                    <div class="user">
                                                                        <img src="images/user2.png" alt="image">
                                                                        <a href="agent-profile.html">Walter White</a>
                                                                    </div>
                                                                    <ul class="group-info">
                                                                        <li>
                                                                            <div class="dropdown">
                                                                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <i class="ri-share-line"></i>
                                                                                </button>
                                                                                <ul class="dropdown-menu">
                                                                                    <li>
                                                                                        <a href="https://www.facebook.com/" target="_blank">
                                                                                            <i class="ri-facebook-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://twitter.com/" target="_blank">
                                                                                            <i class="ri-twitter-x-line"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://www.instagram.com/" target="_blank">
                                                                                            <i class="ri-instagram-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://bd.linkedin.com/" target="_blank">
                                                                                            <i class="ri-linkedin-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                                                <i class="ri-heart-line"></i>
                                                                            </button>
                                                                        </li>
                                                                        <li>
                                                                            <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                                                <i class="ri-arrow-left-right-line"></i>
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="swiper-slide">
                                                        <div class="properties-item">
                                                            <div class="properties-image">
                                                                <a href="property-details.html">
                                                                    <img src="images/properties3.jpg" alt="image">
                                                                </a>
                                                                <ul class="action">
                                                                    <li>
                                                                        <a href="property-grid.html" class="featured-btn">Featured</a>
                                                                    </li>
                                                                    <li>
                                                                        <div class="media">
                                                                            <span><i class="ri-vidicon-fill"></i></span>
                                                                            <span><i class="ri-image-line"></i>5</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul class="link-list">
                                                                    <li>
                                                                        <a href="property-grid.html" class="link-btn">Apartment</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="property-grid.html" class="link-btn">For Sale</a>
                                                                    </li>
                                                                </ul>
                                                                <ul class="info-list">
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/bed_1.svg" alt="bed">
                                                                        </div>
                                                                        <span>6</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/bathroom_1.svg" alt="bathroom">
                                                                        </div>
                                                                        <span>4</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/parking_1.svg" alt="parking">
                                                                        </div>
                                                                        <span>1</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/area_1.svg" alt="area">
                                                                        </div>
                                                                        <span>3250</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="properties-content">
                                                                <div class="top">
                                                                    <div class="title">
                                                                        <h3>
                                                                            <a href="property-details.html">Single-Family Homes</a>
                                                                        </h3>
                                                                        <span>194 Mercer Street, NY 10012, USA</span>
                                                                    </div>
                                                                    <div class="price">$77,000</div>
                                                                </div>
                                                                <div class="bottom">
                                                                    <div class="user">
                                                                        <img src="images/user3.png" alt="image">
                                                                        <a href="agent-profile.html">Jane Ronan</a>
                                                                    </div>
                                                                    <ul class="group-info">
                                                                        <li>
                                                                            <div class="dropdown">
                                                                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <i class="ri-share-line"></i>
                                                                                </button>
                                                                                <ul class="dropdown-menu">
                                                                                    <li>
                                                                                        <a href="https://www.facebook.com/" target="_blank">
                                                                                            <i class="ri-facebook-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://twitter.com/" target="_blank">
                                                                                            <i class="ri-twitter-x-line"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://www.instagram.com/" target="_blank">
                                                                                            <i class="ri-instagram-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://bd.linkedin.com/" target="_blank">
                                                                                            <i class="ri-linkedin-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                                                <i class="ri-heart-line"></i>
                                                                            </button>
                                                                        </li>
                                                                        <li>
                                                                            <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                                                <i class="ri-arrow-left-right-line"></i>
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="swiper-slide">
                                                        <div class="properties-item">
                                                            <div class="properties-image">
                                                                <a href="property-details.html">
                                                                    <img src="images/properties4.jpg" alt="image">
                                                                </a>
                                                                <ul class="action">
                                                                    <li>
                                                                        <div class="media">
                                                                            <span><i class="ri-vidicon-fill"></i></span>
                                                                            <span><i class="ri-image-line"></i>5</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul class="link-list">
                                                                    <li>
                                                                        <a href="property-grid.html" class="link-btn">Apartment</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="property-grid.html" class="link-btn">For Sale</a>
                                                                    </li>
                                                                </ul>
                                                                <ul class="info-list">
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/bed_1.svg" alt="bed">
                                                                        </div>
                                                                        <span>6</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/bathroom_1.svg" alt="bathroom">
                                                                        </div>
                                                                        <span>4</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/parking_1.svg" alt="parking">
                                                                        </div>
                                                                        <span>1</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/area_1.svg" alt="area">
                                                                        </div>
                                                                        <span>3250</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="properties-content">
                                                                <div class="top">
                                                                    <div class="title">
                                                                        <h3>
                                                                            <a href="property-details.html">Newly Built Homes</a>
                                                                        </h3>
                                                                        <span>194 Mercer Street, NY 10012, USA</span>
                                                                    </div>
                                                                    <div class="price">$33,000</div>
                                                                </div>
                                                                <div class="bottom">
                                                                    <div class="user">
                                                                        <img src="images/user4.png" alt="image">
                                                                        <a href="agent-profile.html">Jack Smith</a>
                                                                    </div>
                                                                    <ul class="group-info">
                                                                        <li>
                                                                            <div class="dropdown">
                                                                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <i class="ri-share-line"></i>
                                                                                </button>
                                                                                <ul class="dropdown-menu">
                                                                                    <li>
                                                                                        <a href="https://www.facebook.com/" target="_blank">
                                                                                            <i class="ri-facebook-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://twitter.com/" target="_blank">
                                                                                            <i class="ri-twitter-x-line"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://www.instagram.com/" target="_blank">
                                                                                            <i class="ri-instagram-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://bd.linkedin.com/" target="_blank">
                                                                                            <i class="ri-linkedin-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                                                <i class="ri-heart-line"></i>
                                                                            </button>
                                                                        </li>
                                                                        <li>
                                                                            <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                                                <i class="ri-arrow-left-right-line"></i>
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="swiper-slide">
                                                        <div class="properties-item">
                                                            <div class="properties-image">
                                                                <a href="property-details.html">
                                                                    <img src="images/properties5.jpg" alt="image">
                                                                </a>
                                                                <ul class="action">
                                                                    <li>
                                                                        <a href="property-grid.html" class="featured-btn">Featured</a>
                                                                    </li>
                                                                    <li>
                                                                        <div class="media">
                                                                            <span><i class="ri-vidicon-fill"></i></span>
                                                                            <span><i class="ri-image-line"></i>5</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul class="link-list">
                                                                    <li>
                                                                        <a href="property-grid.html" class="link-btn">Apartment</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="property-grid.html" class="link-btn">For Sale</a>
                                                                    </li>
                                                                </ul>
                                                                <ul class="info-list">
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/bed_1.svg" alt="bed">
                                                                        </div>
                                                                        <span>6</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/bathroom_1.svg" alt="bathroom">
                                                                        </div>
                                                                        <span>4</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/parking_1.svg" alt="parking">
                                                                        </div>
                                                                        <span>1</span>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icon">
                                                                            <img src="images/area_1.svg" alt="area">
                                                                        </div>
                                                                        <span>3250</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="properties-content">
                                                                <div class="top">
                                                                    <div class="title">
                                                                        <h3>
                                                                            <a href="property-details.html">Senior Apartments</a>
                                                                        </h3>
                                                                        <span>194 Mercer Street, NY 10012, USA</span>
                                                                    </div>
                                                                    <div class="price">$65,000</div>
                                                                </div>
                                                                <div class="bottom">
                                                                    <div class="user">
                                                                        <img src="images/user5.png" alt="image">
                                                                        <a href="agent-profile.html">Jenny Loren</a>
                                                                    </div>
                                                                    <ul class="group-info">
                                                                        <li>
                                                                            <div class="dropdown">
                                                                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <i class="ri-share-line"></i>
                                                                                </button>
                                                                                <ul class="dropdown-menu">
                                                                                    <li>
                                                                                        <a href="https://www.facebook.com/" target="_blank">
                                                                                            <i class="ri-facebook-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://twitter.com/" target="_blank">
                                                                                            <i class="ri-twitter-x-line"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://www.instagram.com/" target="_blank">
                                                                                            <i class="ri-instagram-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="https://bd.linkedin.com/" target="_blank">
                                                                                            <i class="ri-linkedin-fill"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                                                <i class="ri-heart-line"></i>
                                                                            </button>
                                                                        </li>
                                                                        <li>
                                                                            <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                                                <i class="ri-arrow-left-right-line"></i>
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div class="properties-pagination"></div>
                                            </div>
                                        </div>
                                        <div class="contact-details">
                                            <h3>Contact Details</h3>
                                            <ul class="list">
                                                <li>
                                                    <span>Email:</span>
                                                    <a href="#/cdn-cgi/l/email-protection#97f4f8f9e3f6f4e3d7fff2fbfbf8b9f4f8fa"><span class="__cf_email__" data-cfemail="197a76776d787a6d59717c757576377a7674">[email�&nbsp;protected]</span></a>
                                                </li>
                                                <li>
                                                    <span>Phone:</span>
                                                    <a href="tel:01234567890">0123 456 7890</a>
                                                </li>
                                                <li>
                                                    <span>Location:</span>
                                                    New York, USA
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="subscribe-wrap-area">
            <div class="container" data-cues="slideInUp">
                <div class="subscribe-wrap-inner-area">
                    <div class="subscribe-content">
                        <h2>Subscribe To Our Newsletter</h2>
                        <form class="subscribe-form">
                            <input type="search" class="form-control" placeholder="Enter your email"/>
                            <button type="submit" class="default-btn">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        

        
      
        <button type="button" id="back-to-top" class="position-fixed text-center border-0 p-0">
            <i class="ri-arrow-up-s-line"></i>
        </button>
       

       
    
</div>

)



}

export default Details;