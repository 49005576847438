import React, { useState, useEffect } from "react";
import "./App.css";
import { Route, BrowserRouter, Routes, HashRouter } from "react-router-dom";
import { Layout, ConfigProvider } from "antd";
import enUS from "antd/lib/locale-provider/en_US";
import { useJwt, decodeToken } from "react-jwt";
import { UserContext } from "./globalContext";
import HomeScreen from "./Component/Home/Home";
import Login from "./Component/Login/Login";
import Signup from "./Component/Signupscreen/Signup";
import AllProperty from "./Component/AllProperties/AllProperty";
import Details from "./Component/DetailsAll/Details";
import Contect from './Component/Contect/Contect';
import Postproperties from './Component/Postproperties/Postproperties';






function App() {
const [waitstate, setWaitstate] = useState(true);
const [authToken, setAuthToken] = useState(null);
// const { authToken, isExpired } = useJwt(JSON.parse(localStorage.getItem("authtoken")));

useEffect(() => {

if (
localStorage.getItem("authtoken") !== null &&
localStorage.getItem("authtoken") !== undefined
) {

const myDecodedToken = decodeToken(JSON.parse(localStorage.getItem("authtoken")));
if(myDecodedToken.role===undefined){
myDecodedToken.role="test"
setAuthToken(myDecodedToken);
}
else{
setAuthToken(myDecodedToken);
}

setWaitstate(false);
localStorage.setItem("username", JSON.parse(localStorage.getItem("authtoken")).name)
} else {
setWaitstate(false);
setAuthToken(null);
// window.location.href = "/";
//navigate("/LoginScreen", { replace: true });

}
}, []);
const validation = () => {

const myDecodedToken = decodeToken(JSON.parse(localStorage.getItem("authtoken")));
setAuthToken(myDecodedToken);
}


return (
<HashRouter>
  <div className="App">
    {!waitstate && (
    <UserContext.Provider value={ authToken !==null ? { userId: authToken._id===undefined ? 1 : authToken._id,
       userName:authToken.name===undefined ? 1 : authToken.name, memberId: authToken.id===undefined ? 1 : authToken.id, 
       role:authToken.role===undefined ? 1 : authToken.role.name, 
       mobile: authToken.mobile===undefined ? 1 : authToken.mobile,
      memberReferCode: authToken.memberReferCode===undefined ? 1 : authToken.memberReferCode,
       roleId:authToken.role===undefined ? 1 : authToken.role._id,
        registerDate: authToken.registerDate===undefined ? 1 :authToken.registerDate,
       config: { dateFormat: "DD-MMM-YYYY" , datetimeFormat: "DD-MMM-YYYY HH:mm" ,
      datetimeFormatWithoutYear: "DD-MMM HH:mm" , datetimeSecondFormat: "DD-MMM-YYYY HH:mm:ss" ,
      datetimeSecondFormatAMPM: "DD-MMM-YYYY hh:mm:ss A" , timeFormat: "hh:mm:ss A" , } } : { userId: null, config: {
      dateFormat: "DD-MMM-YYYY" , datetimeFormat: "DD-MMM-YYYY HH:mm" , datetimeFormatWithoutYear: "DD-MMM HH:mm" ,
      datetimeSecondFormat: "DD-MMM-YYYY HH:mm:ss" , datetimeSecondFormatAMPM: "DD-MMM-YYYY hh:mm:ss A" ,
      timeFormat: "hh:mm:ss A" , } } }>
      <Layout className="layout">
        {/* {(authToken== undefined) && (authToken== null) &&
        <Nav2 />} */}
        {/* {(authToken && authToken.id !== undefined) && (authToken && authToken.id !== null) &&
        <Nav authToken={authToken} />} */}
        <ConfigProvider locale={enUS}>
          <Routes>
          <Route path="/" element={<HomeScreen/>} />
                  <Route path="Login" element={<Login />} />
                  <Route path="Signup" element={<Signup authToken={authToken} />} />

                  {/* <Route path="Dummy" element={<PrintList/>}/> */}
                  <Route path="HomeScreen" element={<HomeScreen />} />

            <Route path="AllProperty" element={<AllProperty />} />
            <Route path="Details" element={<Details />} />Postproperties
            <Route path="Contect" element={<Contect />} />
            <Route path="Postproperties" element={<Postproperties />} />
          



          </Routes>
        </ConfigProvider>
        
      </Layout>
    </UserContext.Provider>
    )}
  </div>
</HashRouter>
);
}

export default App;