import React from "react";
import properties11 from '../../images/properties11.jpg'
import bed from '../../images/bed.svg'
import bathroom from '../../images/bathroom.svg'
import parking from '../../images/parking.svg'
import area from '../../images/area.svg'
import bed2 from '../../images/bed2.svg'
import bathroom2 from '../../images/bathroom2.svg'
import parking2 from '../../images/parking2.svg'
import area2 from '../../images/area2.svg'
import user2 from '../../images/user2.png'


const AllProperty = () =>{
    const [show, setShow] = React.useState(false);


return(

    <mydiv>


    
    {/* <div class="preloader-area text-center position-fixed top-0 bottom-0 start-0 end-0" id="preloader">
    <div class="loader position-absolute start-0 end-0">
        <img src="images/favicon.png" alt="favicon"/>
        <div class="waviy position-relative">
            <span class="d-inline-block">D</span>
            <span class="d-inline-block">R</span>
            <span class="d-inline-block">E</span>
            <span class="d-inline-block">M</span>
            <span class="d-inline-block">K</span>
            <span class="d-inline-block">O</span>
            <span class="d-inline-block">T</span>
            <span class="d-inline-block">H</span>
            <span class="d-inline-block">I</span>

        </div>
    </div>
</div> */}

    <div class="page-banner-area">
        <div class="container">
            <div class="page-banner-content">
                <h2>Property Top Filter</h2>
                <ul class="list">
                    <li>
                        <a href="#/">Home</a>
                    </li>
                    <li>Property Top Filter</li>
                </ul>
            </div>
        </div>
    </div>
    
    <div class="properties-wrap-area without-wrap-bg pt-120 pb-120">
        <div class="container">
        {show &&   <div class="properties-search-info-tabs">
                <ul class="nav nav-tabs" id="search_tab" role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="sell-tab" data-bs-toggle="tab" href="#sell" role="tab" aria-controls="sell">Sell</a></li>
                    <li class="nav-item"><a class="nav-link" id="rent-tab" data-bs-toggle="tab" href="#rent" role="tab" aria-controls="rent">Rent</a></li>
                    <li class="nav-item"><a class="nav-link" id="invest-tab" data-bs-toggle="tab" href="#invest" role="tab" aria-controls="invest">Invest</a></li>
                </ul>
                <div class="tab-content" id="search_tab_content">
                    <div class="tab-pane fade show active" id="sell" role="tabpanel">
                        <form class="search-form">
                            <div class="row justify-content-center align-items-end">
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Looking For</label>
                                        <select class="form-select form-control">
                                            <option selected="">Property type</option>
                                            <option value="1">Multifamily</option>
                                            <option value="2">Detached house</option>
                                            <option value="3">Industrial</option>
                                            <option value="4">Townhouse</option>
                                            <option value="5">Apartment</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Location</label>
                                        <select class="form-select form-control">
                                            <option selected="">All cities</option>
                                            <option value="1">Liverpool</option>
                                            <option value="2">Bristol</option>
                                            <option value="3">Nottingham</option>
                                            <option value="4">Leicester</option>
                                            <option value="5">Coventry</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Your Price</label>
                                        <input type="text" class="form-control" placeholder="Max price"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Min Lot size</label>
                                        <input type="text" class="form-control" placeholder="Property lot size"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select class="form-select form-control">
                                            <option selected="">Property status</option>
                                            <option value="1">Active (55)</option>
                                            <option value="2">Open House (65)</option>
                                            <option value="3">Hot Offer (45)</option>
                                            <option value="4">Sold (78)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="button-group-list">
                                        <button type="submit" class="search-btn">
                                            <i class="ri-search-2-line"></i>
                                        </button>
                                        <button type="submit" class="reset-search-btn">
                                            <i class="ri-refresh-line"></i>
                                            Reset Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="rent" role="tabpanel">
                        <form class="search-form">
                            <div class="row justify-content-center align-items-end">
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Looking For</label>
                                        <select class="form-select form-control">
                                            <option selected="">Property type</option>
                                            <option value="1">Multifamily</option>
                                            <option value="2">Detached house</option>
                                            <option value="3">Industrial</option>
                                            <option value="4">Townhouse</option>
                                            <option value="5">Apartment</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Location</label>
                                        <select class="form-select form-control">
                                            <option selected="">All cities</option>
                                            <option value="1">Liverpool</option>
                                            <option value="2">Bristol</option>
                                            <option value="3">Nottingham</option>
                                            <option value="4">Leicester</option>
                                            <option value="5">Coventry</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Your Price</label>
                                        <input type="text" class="form-control" placeholder="Max price"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Min Lot size</label>
                                        <input type="text" class="form-control" placeholder="Property lot size"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select class="form-select form-control">
                                            <option selected="">Property status</option>
                                            <option value="1">Active (55)</option>
                                            <option value="2">Open House (65)</option>
                                            <option value="3">Hot Offer (45)</option>
                                            <option value="4">Sold (78)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="button-group-list">
                                        <button type="submit" class="search-btn">
                                            <i class="ri-search-2-line"></i>
                                        </button>
                                        <button type="submit" class="reset-search-btn">
                                            <i class="ri-refresh-line"></i>
                                            Reset Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="invest" role="tabpanel">
                        <form class="search-form">
                            <div class="row justify-content-center align-items-end">
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Looking For</label>
                                        <select class="form-select form-control">
                                            <option selected="">Property type</option>
                                            <option value="1">Multifamily</option>
                                            <option value="2">Detached house</option>
                                            <option value="3">Industrial</option>
                                            <option value="4">Townhouse</option>
                                            <option value="5">Apartment</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Location</label>
                                        <select class="form-select form-control">
                                            <option selected="">All cities</option>
                                            <option value="1">Liverpool</option>
                                            <option value="2">Bristol</option>
                                            <option value="3">Nottingham</option>
                                            <option value="4">Leicester</option>
                                            <option value="5">Coventry</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Your Price</label>
                                        <input type="text" class="form-control" placeholder="Max price"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Min Lot size</label>
                                        <input type="text" class="form-control" placeholder="Property lot size"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select class="form-select form-control">
                                            <option selected="">Property status</option>
                                            <option value="1">Active (55)</option>
                                            <option value="2">Open House (65)</option>
                                            <option value="3">Hot Offer (45)</option>
                                            <option value="4">Sold (78)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="button-group-list">
                                        <button type="submit" class="search-btn">
                                            <i class="ri-search-2-line"></i>
                                        </button>
                                        <button type="submit" class="reset-search-btn">
                                            <i class="ri-refresh-line"></i>
                                            Reset Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            <div class="properties-grid-box">
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-7 col-md-6 col-sm-6 col-6">
    <p>                <button title="filter" class="filter" onClick={() => setShow(!show)}>
  <svg viewBox="0 0 512 512" height="1em">
    <path
      d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z"
    ></path>
  </svg>
</button></p>



      

                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-6 col-6">
                        <div class="d-flex align-items-center justify-content-end">
                            <select class="form-select">
                                <option selected="">Recommend</option>
                                <option value="1">Sort by newest</option>
                                <option value="3">Sort by latest</option>
                                <option value="1">Sort by popularity</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center" data-cues="slideInUp">
                <div class="col-xl-6 col-md-12">
                    <div class="properties-inner-card with-wrap-color">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="properties-image">
                                    <a href="#/" class="featured-btn">Featured</a>
                                    <div class="media">
                                        <span><i class="ri-vidicon-fill"></i></span>
                                        <span><i class="ri-image-line"></i>5</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div class="properties-content">
                                    <div class="info">
                                        <ul class="link-list">
                                            <li>
                                                <a href="#/" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="#/" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="title">
                                        <h3>
                                            <a href="#/">Industrial Spaces</a>
                                        </h3>
                                        <span>194 Mercer Street, NY 10012, USA</span>
                                    </div>
                                    <ul class="info-list">
                                        <li>
                                            <div class="icon">
                                                <img src={bed2} alt="" />
                                            </div>
                                            <span>6</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={bathroom2} alt="" />
                                            </div>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                            <img src={parking2} alt="" />

                                            </div>
                                            <span>1</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={area2} alt="" />
                                            </div>
                                            <span>3250</span>
                                        </li>
                                    </ul>
                                    <div class="price-and-user">
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="#/">Thomas Klarck</a>
                                        </div>
                                        <div class="price">$95,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-12">
                    <div class="properties-inner-card with-wrap-color">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="properties-image bg-two">
                                    <a href="#/" class="featured-btn">Featured</a>
                                    <div class="media">
                                        <span><i class="ri-vidicon-fill"></i></span>
                                        <span><i class="ri-image-line"></i>5</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div class="properties-content">
                                    <div class="info">
                                        <ul class="link-list">
                                            <li>
                                                <a href="#/" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="#/" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="title">
                                        <h3>
                                            <a href="#/">Beach Front Property</a>
                                        </h3>
                                        <span>194 Mercer Street, NY 10012, USA</span>
                                    </div>
                                    <ul class="info-list">
                                        <li>
                                            <div class="icon">
                                                    <img src={bed2} alt="" />
                                            </div>
                                            <span>6</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={bathroom2} alt="" />
                                            </div>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={parking2} alt="" />
                                            </div>
                                            <span>1</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={area2} alt="" />
                                            </div>
                                            <span>3250</span>
                                        </li>
                                    </ul>
                                    <div class="price-and-user">
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="#/">Walter White</a>
                                        </div>
                                        <div class="price">$77,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-12">
                    <div class="properties-inner-card with-wrap-color">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="properties-image">
                                    <a href="#/" class="featured-btn">Featured</a>
                                    <div class="media">
                                        <span><i class="ri-vidicon-fill"></i></span>
                                        <span><i class="ri-image-line"></i>5</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div class="properties-content">
                                    <div class="info">
                                        <ul class="link-list">
                                            <li>
                                                <a href="#/" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="#/" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="title">
                                        <h3>
                                            <a href="#/">Industrial Spaces</a>
                                        </h3>
                                        <span>194 Mercer Street, NY 10012, USA</span>
                                    </div>
                                    <ul class="info-list">
                                        <li>
                                            <div class="icon">
                                                <img src={bed2} alt="" />
                                            </div>
                                            <span>6</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={bathroom2} alt="" />
                                            </div>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                            <img src={parking2} alt="" />

                                            </div>
                                            <span>1</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={area2} alt="" />
                                            </div>
                                            <span>3250</span>
                                        </li>
                                    </ul>
                                    <div class="price-and-user">
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="#/">Thomas Klarck</a>
                                        </div>
                                        <div class="price">$95,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-12">
                    <div class="properties-inner-card with-wrap-color">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="properties-image bg-two">
                                    <a href="#/" class="featured-btn">Featured</a>
                                    <div class="media">
                                        <span><i class="ri-vidicon-fill"></i></span>
                                        <span><i class="ri-image-line"></i>5</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div class="properties-content">
                                    <div class="info">
                                        <ul class="link-list">
                                            <li>
                                                <a href="#/" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="#/" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="title">
                                        <h3>
                                            <a href="#/">Beach Front Property</a>
                                        </h3>
                                        <span>194 Mercer Street, NY 10012, USA</span>
                                    </div>
                                    <ul class="info-list">
                                        <li>
                                            <div class="icon">
                                                    <img src={bed2} alt="" />
                                            </div>
                                            <span>6</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={bathroom2} alt="" />
                                            </div>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={parking2} alt="" />
                                            </div>
                                            <span>1</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={area2} alt="" />
                                            </div>
                                            <span>3250</span>
                                        </li>
                                    </ul>
                                    <div class="price-and-user">
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="#/">Walter White</a>
                                        </div>
                                        <div class="price">$77,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-12">
                    <div class="properties-inner-card with-wrap-color">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="properties-image">
                                    <a href="#/" class="featured-btn">Featured</a>
                                    <div class="media">
                                        <span><i class="ri-vidicon-fill"></i></span>
                                        <span><i class="ri-image-line"></i>5</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div class="properties-content">
                                    <div class="info">
                                        <ul class="link-list">
                                            <li>
                                                <a href="#/" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="#/" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="title">
                                        <h3>
                                            <a href="#/">Industrial Spaces</a>
                                        </h3>
                                        <span>194 Mercer Street, NY 10012, USA</span>
                                    </div>
                                    <ul class="info-list">
                                        <li>
                                            <div class="icon">
                                                <img src={bed2} alt="" />
                                            </div>
                                            <span>6</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={bathroom2} alt="" />
                                            </div>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                            <img src={parking2} alt="" />

                                            </div>
                                            <span>1</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={area2} alt="" />
                                            </div>
                                            <span>3250</span>
                                        </li>
                                    </ul>
                                    <div class="price-and-user">
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="#/">Thomas Klarck</a>
                                        </div>
                                        <div class="price">$95,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-12">
                    <div class="properties-inner-card with-wrap-color">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="properties-image bg-two">
                                    <a href="#/" class="featured-btn">Featured</a>
                                    <div class="media">
                                        <span><i class="ri-vidicon-fill"></i></span>
                                        <span><i class="ri-image-line"></i>5</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div class="properties-content">
                                    <div class="info">
                                        <ul class="link-list">
                                            <li>
                                                <a href="#/" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="#/" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="title">
                                        <h3>
                                            <a href="#/">Beach Front Property</a>
                                        </h3>
                                        <span>194 Mercer Street, NY 10012, USA</span>
                                    </div>
                                    <ul class="info-list">
                                        <li>
                                            <div class="icon">
                                                    <img src={bed2} alt="" />
                                            </div>
                                            <span>6</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={bathroom2} alt="" />
                                            </div>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={parking2} alt="" />
                                            </div>
                                            <span>1</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={area2} alt="" />
                                            </div>
                                            <span>3250</span>
                                        </li>
                                    </ul>
                                    <div class="price-and-user">
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="#/">Walter White</a>
                                        </div>
                                        <div class="price">$77,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
    

    
    <div class="subscribe-wrap-area">
        <div class="container" data-cues="slideInUp">
            <div class="subscribe-wrap-inner-area">
                <div class="subscribe-content">
                    <h2>Subscribe To Our Newsletter</h2>
                    <form class="subscribe-form">
                        <input type="search" class="form-control" placeholder="Enter your email"/>
                        <button type="submit" class="default-btn">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    
    <button type="button" id="back-to-top" class="position-fixed text-center border-0 p-0">
        <i class="ri-arrow-up-s-line"></i>
    </button>
    
    
    
    </mydiv>
)



}

export default AllProperty;